<template>
  <!--    编辑-->
  <el-dialog
    :title="formTitle"
    :visible.sync="messageBox"
    width="70vw"
    class="addAlameBox"
    :close-on-click-modal="false"
  >
    <FormBox
      ref="formRef"
      :showFlag="showFlag"
      :formArr="formArr"
      :record="addForm"
      @saveForm="saveForm"
      @handleCancel="handleCancel"
      :fileListModify="fileListModify"
    ></FormBox>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";
export default {
  components: { FormBox },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "标题",
          key: "title",
          type: "Input",
        },
        {
          label: "发布人",
          key: "fbr",
          type: "Input",
        },
        {
          label: "发布时间",
          key: "fbsj",
          type: "DateTime",
        },
        {
          label: "内容",
          key: "content",
          type: "Editor",
          width: "100%",
        },
        {          
          label: "附件",
          key: "fjs",
          type: "FileUpload",
          width: "100%",
          limit: 9
        }
      ],
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: [],
      fileTypes: [
        {
          suff: "jpg",
          type: 'image/png'
        },
        {
          suff: "jpeg",
          type: 'image/png'
        },
        {
          suff: "png",
          type: 'image/png'
        },
        {
          suff: "doc",
          type: 'application/msword'
        },
        {
          suff: "docx",
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        },
        {
          suff: "xls",
          type: 'application/vnd.ms-excel'
        },
        {
          suff: "xlsx",
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        {
          suff: "pdf",
          type: 'pplication/pdf'
        },
      ]
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        this.addForm = newVal;
        if(newVal.id){
          this.getDetailData(newVal);
        }        
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if(this.formTitle == '详情'){
      // this.formArr.forEach(item => {
      //   item.disabled = true
      // })
    }
  },
  methods: {
    getDetailData(data){
      this.$get(`/zcfg/detail/${data.id}`, {}).then((res) => {
        if (res.data.state == "success") {
          if(res.data.data && res.data.data.fjs && res.data.data.fjs.length > 0){
            // 文件处理
            this.fileListModify = []
            let files = res.data.data.fjs;
            files.forEach(async item => {
              let rf = await this.$getBlob("/fileops/show", {
                fileName: item,
              });
          
              let fileNames = rf.config.params.fileName;
              let fileData = fileNames.split(".")[1];
              let fileType = this.fileTypes.filter(item => item.suff == fileData)[0].type
              let blob = new Blob([rf.data],{type: fileType})
              let url = window.URL.createObjectURL(blob);
              this.fileListModify.push({name: item, url: url, blob, fileData});
            })
            
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const { fileList } = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
            item.response &&
            item.response.data &&
            item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

    // 确认提交
    saveForm(param) {

      let url = "/zcfg/save";
      // if(this.formTitle == '新增'){
      //   delete this.addForm.id;
      // }

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>